import React from 'react';
import { css } from '@emotion/react';
import { navigate } from 'gatsby';
import RoutePaths from '@/utils/routes';
import PublicProvider from '@/components/providers/PublicProvider';
import { SettingTabsKey } from '@/models';
import AppButtonV2 from '@/components/AppButtonV2';

const RequiredSubscriptionPage: React.FC = () => {
  return (
    <PublicProvider>
      <div
        css={css`
          min-height: 100vh;
          padding-top: 30px;
          padding-bottom: 0px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #e7edf3;
          position: relative;
          width: 100%;
          padding: 30px 20px 30px;
          .section-subscription-required {
            max-width: 480px;
            width: 100%;
            font-weight: 300;
            .wrapper-subscription-required {
              padding: 20px;
              border-radius: 20px;
              background-color: #150056;
              background-image: none;
              backdrop-filter: blur(14px);
              color: #fff;
              .notfound-header {
                font-family: 'Fira Sans', sans-serif;
                margin-top: 20px;
                border-radius: 5px;
                color: #fff;
                text-align: center;
                margin-bottom: 16px;
                font-size: 34px;
                line-height: 36px;
                font-weight: 700;
              }
              .message {
                margin-bottom: 22px;
                text-align: center;
                font-weight: 500;
              }
            }
          }
        `}
      >
        <div className="section-subscription-required">
          <div className="wrapper-subscription-required">
            <h2 className="notfound-header">Activate Subscription Package</h2>
            <p className="message">
              This page requires activation of a subscription package. Please activate the subscription package to
              access additional features.
            </p>
            <AppButtonV2
              size="large"
              width="100%"
              color="Pin"
              onClick={() => {
                navigate(`${RoutePaths.SETTINGS}/?tab=${SettingTabsKey.SUBSCRIPTION}`);
              }}
            >
              Okay
            </AppButtonV2>
          </div>
        </div>
      </div>
    </PublicProvider>
  );
};

export default RequiredSubscriptionPage;
